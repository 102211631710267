import { styled } from 'styled-components';
import { apple, BG_login, google, kakao, logo_grey, naver} from '../assets';
import { useEffect } from 'react';
import { removeCookie } from '../api/cookie';
import { useNavigate } from 'react-router-dom';

const S = {
  Display : styled.section`
    width : 100%;
    height : 100vh;
    background : no-repeat center/cover url(${BG_login});

    display : flex;
    flex-direction : column;
    justify-content : center;
    align-items : center;

    padding : 40px 20px;
    box-sizing : border-box;

    overflow-y : scroll;

    @media (max-height : 500px) {
      padding : 0 20px;
      justify-content : flex-start;
    }
    
  `,
  sub_title : styled.div`
    color : #fff;
    font-size : 2rem;
    font-weight : 400;
    line-height : 120%;

    @media (max-height : 500px) {
      padding-top : 40px;
    }
  `,
  Title : styled.h1`
    color : #fff;
    font-size : 3rem;
    font-weight : 900;
    line-height : 130%;
    margin : 0 0 60px;
  `,
  Button_section : styled.section`
    width : 100%;
    display : flex;
    flex-direction : column;
    align-items : center; 
  `,
  Login_box : styled.div`
    max-width : 353px;
    width : 100%;
    padding : 16px;
    box-sizing : border-box;
    color : #fff;
    border-radius : 10px;
    border : 1px solid #fff;
    cursor : pointer;
    margin : 5px 0;

    display : flex;
    justify-content : space-between;
    align-items : center;
  `,
  Logo : styled.img`
    width : 24px;
    height : 24px;

    &.logo {
      width : 75px;
      height : 18px;

      position : absolute;
      bottom : 40px;

      @media (max-height : 500px) {
        position : relative;
        padding : 80px 0 20px;
      }
    }
  `
}

const Login = () => {

  const navigate = useNavigate();

  const googlelogin = () => {
    const url = 'https://accounts.google.com/o/oauth2/v2/auth?client_id='
    + process.env.REACT_APP_GOOGLE_KEY + '&redirect_uri=' 
    + process.env.REACT_APP_REDIRECT_URL
    + '/google'
    + '&response_type=code&scope=email profile'
    
    window.location.href = url

  }

  const naverlogin = () => {
    const naver_url = 'https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id='
    + process.env.REACT_APP_NAVER_KEY + '&redirect_uri='
    + process.env.REACT_APP_REDIRECT_URL
    + '/naver'
    + '&state='
    + 'asd2222222';

    window.location.href = naver_url;
  }

  const kakaologin = () => {
    const kakao_url = 'https://kauth.kakao.com/oauth/authorize?client_id='
    + process.env.REACT_APP_KAKAO_KEY + '&redirect_uri='
    + process.env.REACT_APP_REDIRECT_URL + '/kakao'
    + '&response_type=code';

    window.location.href = kakao_url;
  }

  const applelogin = async (e) => {
    // const apple_url = 'https://appleid.apple.com/auth/authorize?'
    // + 'client_id=' + process.env.REACT_APP_APPLE_KEY
    // + '&response_mode=form_post'
    // + '&response_type=code'
    // + '&scope=name email'
    // // + '&redirect_uri=' + process.env.REACT_APP_REDIRECT_URL + '/apple'
    // + '&redirect_uri=' + 'https://ad88-210-217-92-39.ngrok-free.app/oauth' + '/apple'

    // window.location.href = apple_url;

    e.preventDefault();

    window.AppleID.auth.init({
      clientId : 'com.jikjicloud.jikjicloud.service',
      scope : 'email name',
      redirectURI : process.env.REACT_APP_REDIRECT_URL+'/apple',
      // redirectURI : 'https://ad88-210-217-92-39.ngrok-free.app/oauth/apple',
      usePopup : true,
    })

    try {
      const res = await window.AppleID.auth.signIn();
      console.log(res)
      navigate('/oauth/apple', {state : res}) 
    } catch(err) {
      console.log(err)
    }


  }

  useEffect(() => {

    removeCookie('role');
    removeCookie('name');
    removeCookie('Refreshtoken');

  }, [])

  return (
    <S.Display>
      <S.sub_title>Welcome to</S.sub_title>
      <S.Title>JIKJILABS</S.Title>

      <S.Login_box onClick={googlelogin}>
        <S.Logo src={google}/>
        구글 계정으로 로그인
        <span/>
      </S.Login_box>
      <S.Login_box onClick={naverlogin}>
        <S.Logo src={naver}/>
        네이버 계정으로 로그인
        <span/>
      </S.Login_box>
      <S.Login_box onClick={kakaologin}>
        <S.Logo src={kakao}/>
        카카오 계정으로 로그인
        <span/>
      </S.Login_box>
      <S.Login_box onClick={applelogin}>
        <S.Logo src={apple}/>
        애플 계정으로 로그인
        <span/>
      </S.Login_box>

      <S.Logo src={logo_grey} className='logo'/>
    </S.Display>
  )
}

export default Login;