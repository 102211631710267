import { useEffect, useRef, useState } from 'react';
import { styled } from 'styled-components';
import { createInstance, getImage, getSpec } from '../api/api';
import { useNavigate } from 'react-router-dom';
import Loading from '../components/Loading';
import { getCookie } from '../api/cookie';
import { Button, Ssh } from '../components';
import { dropdown_grey } from '../assets';


const Create = () => {

  const [select, setSelect] = useState("");
  const [state, setState] = useState("");
  const [warning, setWarning] = useState(false);

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [key, setKey] = useState(null);
  const [header, setHeader] = useState(null);


  const tmptimer = async () => {
    await new Promise(resolve => setTimeout(resolve, 10000))
  }

  const closessh = () => {
    setDownloading(false)
    navigate('/dashboard')
  }


  // ver2
  const [nameerror, setNameerror] = useState('');
  const role = decodeURIComponent(getCookie('role'));

  const [gpudata, setGpudata] = useState({
    "modelName" : "NVIDIA H100 80GB HBM3",
    "availableNumbers": []
  });

  const [storagedata, setStoragedata] = useState(
    ["100GB", "500GB", "1TB"]
  )

  const [imagedata ,setImagedata] = useState([])

  const getspec = async () => {
    try{
      const res = await getSpec();
      setGpudata(res.data)
      setGpu(res.data?.modelName+'*'+res.data?.availableNumbers[0])
      if (res.data.availableNumbers.length < 1) {
        setGpu('')
      }

      const resimage = await getImage();

      setImagedata(resimage.data)
      setImage(resimage.data[0].image)
    } catch(e) {
      console.log(e)
    }
  }

  useEffect(() => {
    getspec();
  }, [])

  const [name, setName] = useState("");
  const [gpu, setGpu] = useState(gpudata?.modelName+'*'+gpudata?.availableNumbers[0] || '');
  const [storage, setStorage] = useState(storagedata[0]);
  const [image, setImage] = useState('');

  const submit = async () => {
    console.log(role)
    if (role === 'guest' || role === 'undefined') {
      alert('서비스 준비중입니다.')
    } else if(nameerror || name === '') {
      alert('Name을 입력해주세요')
    } else if (gpu === '') {
      alert('생성 가능한 인스턴스가 없습니다.')
    } else {
      try {
        setLoading(true)

        const key = await createInstance({name, gpu, storage, image, role})
        let filename = 'unknown.pem'
        setHeader(key.headers['content-type'])
        filename = key.headers['content-disposition']

        await tmptimer()
        
        setLoading(false);
        
        const blobData = new Blob([key.data],{type:header})

        const [fileNameMatch] = filename.split(';').filter((str) => str.includes("filename"));
        if (fileNameMatch)[,filename] = fileNameMatch.split("=");

        const link = document.getElementById('download')
        link.download = filename
        link.href = URL.createObjectURL(blobData);
        link.target = "_blank"
        
        link.click();

        URL.revokeObjectURL(blobData)

        navigate('/dashboard')
        // setDownloading(true);
      } catch (err) {
        setLoading(false);
        alert("인스턴스 생성에 실패했습니다.")
        console.log(err)
      }

    }
  }

  const checkname = (e) => {
    const regex = /^[A-Za-z0-9_-]+$/;

    if(e.target.value.trim() === ''){
      setNameerror('blank')
    } else if (!regex.test(e.target.value)) {
      setNameerror('char')
    } else {
      setNameerror('');
      setName(e.target.value)
    }
  }

  return (
    <S.Main>

      {loading && (
        <Loading state={loading}/>
      )}

      {/* {downloading && (
        <Ssh closemodal={closessh} keydata={key} header={header} name={filename}/>
      )} */}

      <S.Title className='first'>Name</S.Title>
      <S.Name_Input placeholder='Please enter the instance name' className={nameerror ? 'error' : ''} onChange={e => checkname(e)}/>
      {nameerror!=='blank' ? (nameerror === 'char' ? <S.Error>Please enter the instance name only using alphanumeric, -, _</S.Error> : <></>): <S.Error>Please enter the instance name</S.Error>}
      <S.Title>GPU</S.Title>
      <S.Select onChange={e => setGpu(e.target.value)}>
        {gpudata.availableNumbers.length < 1 && <option value=''>생성 가능한 인스턴스가 없습니다.</option>}
        {gpudata.availableNumbers?.map((item, key) => (
          <option value={gpudata.modelName + '*' +item} key={key}>{gpudata.modelName} * {item}</option>
        ))}
      </S.Select>
      <S.Title>Image</S.Title>
      <S.Select onChange={e => setImage(e.target.value)}>
      {imagedata.length < 1 && <option value=''>생성 가능한 이미지가 없습니다.</option>}
        {imagedata?.map((item, key) => (
          <option value={item.image} key={key}>{item.name}</option>
        ))}
      </S.Select>
      <S.Title>Storage</S.Title>
      <S.Select className='last' onChange={e => setStorage(e.target.value)}>
        {storagedata?.map((item, key) => (
          <option value={item} key={key}>{item}</option>
        ))}
      </S.Select>

      <Button state="default" text="Deploy" full={true} onClick={submit}/>
      
      <a id="download" className='down'>download</a>
    </S.Main>
  )
}


const S = {
  Main : styled.section`
    box-sizing : border-box;
    padding : 40px 20px; 

    & .down {
      visibility : hidden;
    }
  `,
  Title : styled.div`
    color : #ffffff99;
    font-size : 0.875rem;
    font-weight : 600;
    text-align : start;

    margin : 30px 0 10px;

    &.first {
      margin : 0 0 10px;
    }
  `,
  Name_Input : styled.input`
    width : 100%;
    padding : 18px;
    border-radius : 8px;
    box-sizing : border-box;
    border : 1.5px solid rgba(97, 96, 100, 0.50);
    background: rgba(66, 65, 69, 0.30);
    caret-color : #5B69F6;

    color : #fff;
    font-size : 1.25rem;
    font-weight : 700;

    &:focus {
      outline : 1.5px solid #5B69F6;
    },

    &.error {
      outline : 1.5px solid rgba(241, 74, 74, 0.50);
    }
  `,
  Error : styled.div`
    color : #F14A4A;
    font-size : 0.875rem;
    font-weight : 600;
    text-align : start;
    margin-top : 10px;
  `,

  Select : styled.select`
    width : 100%;
    padding : 18px 50px 18px 18px;
    border-radius : 8px;
    box-sizing : border-box;
    border : 1.5px solid rgba(97, 96, 100, 0.50);
    background : url(${dropdown_grey}) no-repeat calc(100% - 18px) 50%/24px, rgba(66, 65, 69, 0.30);

    color : #fff;
    font-size : 1.25rem;
    font-weight : 700;

    -webkit-appearance:none; /* for chrome */
    -moz-appearance:none; /*for firefox*/
    appearance:none;


    &::-ms-expand {
      display : none;
    }

    &:focus {
      outline : 1.5px solid #5B69F6;
    }

    &.last {
      margin-bottom : 20px;
    }

    overflow : hidden;
    white-space : nowrap;
    text-overflow : ellipsis;

  `
}


export default Create;