import { instance, tokeninstance } from "./interceptor";
import { setCookie } from './cookie';

// login
// 구글 토큰 전송
// 완료
export async function postCode(code, body, platform) {
  let res = []
  if (platform === 'apple') {
    res = await instance.post(`/auth/${platform}`, body)
  } else {
    res = await instance.post(`/auth/${platform}?code=${encodeURIComponent(code)}`)
  }
  const token = res.headers.get('authorization');
  localStorage.setItem("Access_token", token)

  const refresh = res.headers.get('refreshToken')
  setCookie('Refreshtoken', encodeURIComponent(refresh))

  const name = res.data.userInfo.userName;
  setCookie('name', encodeURIComponent(name))

  const email = res.data.userInfo.userEmail;
  setCookie('email', encodeURIComponent(email))

  const role = res.data.userInfo.userRoles;
  if(role.includes("admin")) {
    setCookie('role', 'admin')
  } else if (role.includes("guest")) {
    setCookie('role', 'guest')
  } else if (role.includes("ext_user")) {
    setCookie('role', 'ext_user')
  } else {
    setCookie('role', 'user')
  }

  return res;
}

// get my_instance
// 완료
export async function getInstancelist() {
  const res = await tokeninstance.get('/info/personal')
  return res;
}

export async function getInstancelistAll() {
  const res = await tokeninstance.get('/info/total')
  return res;
}

// pods state
// start, stop, restart, delete
// 완료
export async function postState(state, podId, name) {
  const data = {
    name,
    podId
  }
  const res = await tokeninstance.post(`/pods/${state}`, data)
  return res
}


//pods create
// 완료
export async function createInstance(options) {
  const res = await tokeninstance.post('/pods/create', options)
  return res
}

//pods spec
// 완료
export async function getSpec() {
  const res = await tokeninstance.get('/info/availNum');
  return res
}

export async function getImage() {
  const res = await tokeninstance.get('/info/availImage');
  return res;
}

// 현재 없는 api : main 현재 가격

// export async function getappleid() {
//   const ins
// }