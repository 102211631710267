import { styled } from 'styled-components';
import { Button, Card, Guest } from '../components';
import { useEffect, useState } from 'react';
import { getInstancelist, getInstancelistAll } from '../api/api';
import { useNavigate } from 'react-router-dom';
import { getCookie } from '../api/cookie';

const S = {
  Main : styled.section`
    width : 100%;
    box-sizing : border-box;
    padding : 0 20px 0;
  `,  
  Price_sec : styled.section`
  `,
  Price_title : styled.section`
    color : #ffffff99;
    font-size : 0.875rem;
    font-style : normal;
    font-weight : 600;
    line-height : normal;
  `,
  Price : styled.p`
    color : #fff;
    font-size : 1.875rem;
    font-style : normal;
    font-weight : 700;
    line-height : normal;
    margin : 8px 0 0;

    & span {
      font-weight : 300;
    }
  `,
  Top_section : styled.section`
    width : 100%;
    display : flex;
    justify-content : space-between;
    margin : 40px 0;

    & button {
      margin-top : 12px;
    }
  `,
  Guest_section : styled.section`
    width : 100%;
    background : #616064;
    padding: 20px;
    margin : 40px 0;

    position : relative;
    left : -20px;
  `,
  Guest_info : styled.div`
    margin-bottom : 20px;
    display : flex;
    justify-content : space-between;
  `,
  Guest_text : styled.div`
    color : #fff;
    font-size : 1rem;
    font-weight : 500;
  `,
  Icon : styled.img`
    width : 24px;
    height : 24px;
    cursor : pointer;
  `
}

const Main = () => {

  const navigate = useNavigate();
  const role = decodeURIComponent(getCookie('role'));
  const [data, setData] = useState();
  const [isguest, setIsguest] = useState(true);  
  const [guestopen, setGuestopen] = useState(true);

  const getdata = async () => {
    console.log(role)
    try{
      let res = [];
      if (role === 'admin') {
        res = await getInstancelistAll();
      } else {
        res = await getInstancelist();
      }
      setData(res.data.data)
    } catch(e) {
      console.log(e)
    }
  }

  useEffect(() => {
    getdata()
  }, [])
  
  useEffect(() => {
    if (role !== 'guest' && role !== 'undefined') {
      setIsguest(false);
    }
  }, [role])
  

  const clickbtn = () => {
    navigate('/create')
  }
  
  const refreshdata = () => {
    getdata();
  }

  return (
    <S.Main>

      { isguest
        ?
        <div style={{margin : '20px 0 40px'}}>
          <Button text='Deploy 미리보기' full={true} onClick={() => navigate('/create')}/>
        </div>
        :
        <S.Top_section>
          <S.Price_sec>
            <S.Price_title>결제 예상 금액</S.Price_title>
            <S.Price><span>$</span>2.56</S.Price>
          </S.Price_sec>

          <Button state='default' text='Deploy' onClick={clickbtn}/>
        </S.Top_section>
      }


      { isguest ?
        <>
          { guestopen &&
            <Guest comp={<Card data={data && data[0]}/>} text="되면 다음과 같이 표시합니다." acctext="Deploy가 완료" closebtn={() => setGuestopen(false)}/>
          }
        </>
        :
        <>
          { data?.map((item, key) => (
            <div key={key}>
              {
                (item.ssh && item.user) &&
                <Card data={item} refreshdata={refreshdata}/>
              }
            </div>
          ))}
        </>
      }

    </S.Main>
  )
}

export default Main;