import { useEffect } from "react";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { postCode } from "../api/api";

const OnlyLogin = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const platform = useParams().platform;
  
  async function post(code, body={}) {
    try {
      await postCode(code, body, platform)
      navigate('/')
    } catch{
      navigate('/login')
    }
  }

  useEffect(() => {
    if (platform === 'apple') {
      post("", location.state)
    } else {
      const code = searchParams.get('code')
      post(code)
    }
  }, [])

  return (
    <></>
  )
}

export default OnlyLogin;